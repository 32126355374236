// Overrides
//

a {
    &:hover {
        text-decoration: none;
    }
}

.btn {
    font-weight: 600;
    &:disabled {
        background-color: $gray-300 !important;
        border-color: $gray-300 !important;
        color: $white !important;
        &:hover {
            box-shadow: none !important;
        }
    }
}
.btn-chrome {
    background-color: $blue;
    border-color: $blue;
    color: $white;
    &:hover {
        background-color: $blue;
        border-color: $blue;
        color: $white;
        box-shadow: 0px 4px 12px rgba($blue, 0.4) !important;
    }
    &.active {
        border-color: $blue !important;
    }
    &:disabled {
        background-color: rgba($white, 0.2);
        color: $gray-200;
    }
}
.btn-primary {
    &:hover {
        background-color: $primary;
        border-color: $primary;
        box-shadow: 0px 4px 12px rgba($primary, 0.4) !important;
    }
    &.active {
        border-color: $primary !important;
    }
    &:disabled {
        background-color: rgba($white, 0.2);
        color: $gray-200;
    }
}
.btn-success {
    color: $white;
    &:hover {
        background-color: $green;
        border-color: $green;
        box-shadow: 0px 4px 12px rgba($green, 0.4) !important;
    }
}
.btn-danger {
    &:hover {
        background-color: $danger;
        border-color: $danger;
        box-shadow: 0px 4px 12px rgba($danger, 0.4) !important;
    }
}
.btn-warning {
    &:hover {
        background-color: $warning;
        border-color: $warning;
        box-shadow: 0px 4px 12px rgba($warning, 0.4) !important;
    }
}

.btn-group {
    .btn-primary {
        background-color: $gray-100;
        border-color: $gray-100;
        font-weight: 600;
        color: $gray-800;
        &:hover {
            background-color: $primary;
            border-color: $primary;
            box-shadow: 0px 4px 12px rgba($primary, 0.4) !important;
            translate: scale(1);
        }
        &.active {
            background-color: $primary !important;
            border-color: $primary !important;
        }
    }
    .btn-dark {
        background-color: $gray-100;
        border-color: $gray-100;
        color: $gray-800;
        font-weight: 600;
        &:hover {
            background-color: $gray-200;
            border-color: $gray-200;
            color: $gray-800;
            translate: scale(1);
        }
        &.active {
            background-color: $gray-800 !important;
            border-color: $gray-800 !important;
            color: $white;
        }
    }
}

.btn-sm, .btn-md, .btn-lg {
    border-radius: 2px;
}

.btn-md {
    .ico {
        position: relative;
        top: 4px;
        line-height: 0px;
        font-size: 14px;
    }
}

.form-group {
    margin-bottom: $space-m;
    label {
        color: $gray-600;
    }
}

.custom-select {
    border: 1px solid $gray-300 !important;
    &:hover, &:focus {
      border: 1px solid $gray-500;
      box-shadow: 0px 2px 4px rgba($black, 0.12);
  }
}
.form-control {
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border: 1px solid $gray-300;
  &:hover, &:focus {
      border: 1px solid $gray-500;
      box-shadow: 0px 2px 4px rgba($black, 0.12);
  }
  &:disabled {
      box-shadow: none;
        &:hover, &:focus {
        border: 1px solid $gray-300;
        box-shadow: none;
    }
  }
}

.card {
  @extend .ut-shadow-2;
  border: 1px solid $gray-200;
  background-color: $white;
  border-radius: 2px;
  .card-header {
  }
  .card-body {
  }
  .card-footer {
    background-color: transparent;
  }
}